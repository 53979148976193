import React, { useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

//components
import getScrollAnimationLineal from "../utils/getScrollAnimationLineal";

//styles
import "../styles/productos.css";

//images and SVG
import LogoMafralacHero from "../assets/static/mafralac-logo-hero.svg";
import imgCategoria01 from "../assets/images/img-productos-01.jpg";
import imgCategoria02 from "../assets/images/img-productos-02.jpg";
import imgCategoria03 from "../assets/images/img-productos-03.jpg";
import imgCategoria04 from "../assets/images/img-productos-04.jpg";
import imgCategoria05 from "../assets/images/img-productos-05.jpg";
import imgCategoria06 from "../assets/images/img-productos-06.jpg";

export default function Productos() {
  //animation variables
  const scrollAnimationLineal = useMemo(() => getScrollAnimationLineal(), []);

  //parallax
  window.onscroll = function () {
    var position = window.scrollY || document.documentElement.scrollTop;
    var heroIMG = document.getElementById("heroIMG");
    heroIMG.style.backgroundPositionY = -position * 0.1 + 80 + "%";
  };

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className="containerPaginas pageMinWidth">
      {/* -------------  Hero */}
      <div className="heroImageProductos regularPaddingBottom regularMarginBottomNegative" id="heroIMG">
        <motion.div
          className="innerHeroProductos sidesPadding inner-hero-mobile"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.5 }}
        >
          <motion.img
            className="logoHeroProductos  display-none-mobile "
            src={LogoMafralacHero}
            alt="Logo Mafralac"
            variants={{
              hidden: { opacity: 0, y: 10 },
              show: {
                y: 0,
                opacity: 0.9,
                transition: {
                  duration: 5,
                  type: "spring",
                },
              },
            }}
            initial="hidden"
            animate="show"
          />
          <motion.h1 className="tituloProductos ubicacion-titulo-mobile font08 fontFamBold fontColorWhite" variants={scrollAnimationLineal}>
            Productos
            <motion.p className="tituloProductos ubicacion-titulo-mobile font06 fontFamBold fontColorWhite" variants={scrollAnimationLineal}>
              Ricos en sabor nutricional
            </motion.p>
          </motion.h1>
        </motion.div>
      </div>

      {/* -------------  Primera Seccion Celeste */}
      <div className="divProductos01 gradientBackgroundLightBlueR shadowChico redondeadoDiv bigPaddingBottom regularMarginBottomNegative regularPaddingTop ">
        {/* -------------  Texto final centrado*/}
        <motion.div
          className="txtAlignCenter txtAreaCentral"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <motion.p className="font05 fontFamBold fontColorWhite letterSpaceHigh interLineadoChico  " variants={scrollAnimationLineal}>
            Mediante un exigente proceso de selección de materia prima y seguridad alimenticia en cada fase de la elaboración, nuestra planta de Santa
            Fe produce soluciones ricas, tanto en sabor como en contenido nutricional.{" "}
          </motion.p>
        </motion.div>

        {/* -------------  Grid Categorias Productos */}
        <motion.div
          className="seccion02Productos regularMarginTop sidesPadding"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <Link to="/categoria?cat=Leches+en+Polvo" className="cardCategoriaProducto">
            <p className="linkTituloCategoria fontColorWhite font05 fontFamBold gradientBackgroundUltraLightBlue">Leches</p>
            <div className="containerImg">
              <img className="imgCat" src={imgCategoria01} alt="Categoría Leches en Polvo" />
            </div>
          </Link>
          <Link to="/categoria?cat=Bebidas+Lácteas+en+Polvo" className="cardCategoriaProducto">
            <p className="linkTituloCategoria fontColorWhite font05 fontFamBold gradientBackgroundUltraLightBlue">Bebidas Lácteas en Polvo</p>
            <div className="containerImg">
              <img className="imgCat" src={imgCategoria02} alt="Categoría Bebidas Lácteas en Polvo" />
            </div>
          </Link>
          <a href="https://www.molico.com.ar/productos/#suplementos-proteicos" target="_blank" rel="noreferrer" className="cardCategoriaProducto">
            <p className="linkTituloCategoria fontColorWhite font05 fontFamBold gradientBackgroundUltraLightBlue">Suplementos Deportivos</p>
            <div className="containerImg">
              <img className="imgCat" src={imgCategoria03} alt="Categoría Suplementos Deportivos" />
            </div>
          </a>

          <Link to="/categoria?cat=Queso+y+Aderezos" className="cardCategoriaProducto">
            <p className="linkTituloCategoria fontColorWhite font05 fontFamBold gradientBackgroundUltraLightBlue">Queso y Aderezos</p>
            <div className="containerImg">
              <img className="imgCat" src={imgCategoria04} alt="Categoría Queso y Aderezos" />
            </div>
          </Link>
          <Link to="/categoria?cat=Dulce+de+Leche+y+Pastas+Dulces" className="cardCategoriaProducto">
            <p className="linkTituloCategoria fontColorWhite font05 fontFamBold gradientBackgroundUltraLightBlue">Dulce de Leche y Pastas Dulces</p>
            <div className="containerImg">
              <img className="imgCat" src={imgCategoria05} alt="Categoría Dulce de Leche y Pastas Dulces" />
            </div>
          </Link>
          <Link to="/categoria?cat=Ingredientes+Industriales" className="cardCategoriaProducto">
            <p className="linkTituloCategoria fontColorWhite font05 fontFamBold gradientBackgroundUltraLightBlue">Ingredientes Industriales</p>
            <div className="containerImg">
              <img className="imgCat" src={imgCategoria06} alt="Categoría Ingredientes Industriales" />
            </div>
          </Link>
        </motion.div>
      </div>
    </div>
  );
}
